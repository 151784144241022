import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { ActiveStudies } from '../../features/active-studies/active-studies/active-studies.model';
import { LabsiteOnlineFormConfigurations } from '../../features/volunteer/models/labsiteOnlineFormConfigurations.model';

@Injectable({
  providedIn: 'root'
})
/** Service for Global Repository */
export class GlobalRepositoryService {
  isVolunteerCodeModified = new Subject<boolean>();
  isVolunteerEmailIdModified = new Subject<boolean>();
  canShowVolunteerAppointmentsSubject = new Subject<boolean>();
  canShowStudyProductUsageSubject = new Subject<boolean>();
  isProductUsageLogModified = new Subject<boolean>();
  volunteerCode: string = '';
  volunteerId: string = '';
  volunteerEmailId: string = '';
  userAccountId: string = '';
  userName: string = '';
  labsiteCode: string = '';
  labsitePrefix: string = '';
  canShowVolunteerAppointments: boolean = false;
  canShowStudyProductUsage: boolean = false;
  labsiteDetails: any;
  labsiteOnlineFormConfigurations!: LabsiteOnlineFormConfigurations[];
  heightWeightUnitEnum!: number;
  disclaimersIntroductionText: string = '';
  registrationVisitDescriptionText : string = '';
  legalAdultAge:number|undefined;
  private _activeStudiesData!: ActiveStudies[];

  public get activeStudiesData(): ActiveStudies[] {
    return this._activeStudiesData;
  }
  public set activeStudiesData(value: ActiveStudies[]) {
    this._activeStudiesData = value;
  }

  /** This property is being used to set logged in user labsite details */
  public setLabsiteDetails(labsiteDetails: any) {
    this.labsiteDetails = labsiteDetails;
  }

  public getLabsiteName() {
    return this.labsiteDetails ? this.labsiteDetails.name : '';
  }

  public IsVolunteerCodeModified(): Observable<boolean> {
    return this.isVolunteerCodeModified.asObservable();
  }

  public IsProductUsageLogModified(): Observable<boolean> {
    return this.isProductUsageLogModified.asObservable();
  }

  /** This property is being used to get logged in user volunteer code */
  public getVolunteerCode(): string {
    return this.volunteerCode;
  }

  /** This property is being used to set logged in user volunteer code */
  public setVolunteerCode(volunteerCode: string) {
    this.volunteerCode = volunteerCode;
  }

  /** This property is being used to get logged in user volunteer Id */
  public getVolunteerId(): string {
    return this.volunteerId;
  }

  /** This property is being used to set logged in user volunteer Id */
  public setVolunteerId(volunteerId: string) {
    this.volunteerId = volunteerId;
  }

  public IsVolunteerEmailIdModified(): Observable<boolean> {
    return this.isVolunteerEmailIdModified.asObservable();
  }

  /** This property is being used to get logged in user email id */
  public getVolunteerEmailId(): string {
    return this.volunteerEmailId;
  }

  /** This property is being used to set logged in  user email id */
  public setVolunteerEmailId(volunteerEmailId: string) {
    this.volunteerEmailId = volunteerEmailId;
  }

  /** This property is being used to get logged in user acccount id */
  public getUserAccountId(): string {
    return this.userAccountId;
  }

  /** This property is being used to set logged in  user acccount id */
  public setUserAccountId(userAccountId: string) {
    this.userAccountId = userAccountId;
  }

  public getUserName(): string {
    return this.userName;
  }

  public setUserName(userName: string) {
    this.userName = userName;
  }

  /** This property is being used to removed logged in user volunteer code */
  public removeVolunteerCode() {
    this.volunteerCode = '';
  }

  /** This property is being used to get labsite Code for logged in user */
  public getLabsiteCode(): string {
    return this.labsiteCode;
  }

  /** This property is being used to set labsite Code for logged in user */
  public setLabsiteCode(labsiteCode: string) {
    this.labsiteCode = labsiteCode;
  }

  /** This property is being used to get labsite Prefix Code for logged in user */
  public getLabsitePrefixCode(): string {
    return this.labsitePrefix;
  }

  /** This property is being used to set labsite Prefix Code for logged in user */
  public setLabsitePrefixCode(labsitePrefix: string) {
    this.labsitePrefix = labsitePrefix;
  }

  public getIsDeleteVolunteerAllowed() {
    return this.labsiteDetails ? this.labsiteDetails.isPublicWebsiteVolunteerDeletionAllowed : false;
  }

  public getPrintIdCardPublicWebsiteAllowed() {
    return this.labsiteDetails ? this.labsiteDetails.printIdCardPublicWebsite : false;
  }

  public getIsVolunteerProfileSelfEditingAllowed() {
    return this.labsiteDetails ? this.labsiteDetails.isVolunteerProfileSelfEditingAllowed : false;
  }

  /** This property is being used to set Can Show Volunteer Appointments for logged in user */
  public setCanShowVolunteerAppointments(canShowVolunteerAppointments: boolean) {
    this.canShowVolunteerAppointmentsSubject.next(canShowVolunteerAppointments);
    this.canShowVolunteerAppointments = canShowVolunteerAppointments;
  }

  /** This property is being used to get Can Show Volunteer Appointments for logged in user */
  public getCanShowVolunteerAppointments(): boolean {
    return this.canShowVolunteerAppointments;
  }


   /** This property is being used to set Can Show StudyProductUsage for logged in user */
   public setCanShowStudyProductUsage(canShowStudyProductUsage: boolean) {
    this.canShowStudyProductUsageSubject.next(canShowStudyProductUsage);
    this.canShowStudyProductUsage = canShowStudyProductUsage;
  }

  /** This property is being used to get Can Show StudyProductUsage for logged in user */
  public getCanShowStudyProductUsage(): boolean {
    return this.canShowStudyProductUsage;
  }

  /** This property is being used to get lab site Online Form Configurations for labsite level */
  public getLabsiteOnlineFormConfigurations(): LabsiteOnlineFormConfigurations[] {
    return this.labsiteOnlineFormConfigurations;
  }

  /** his property is being used to set lab site has any Online Form Configurations configured */
  public setLabsiteOnlineFormConfigurations(LabsiteOnlineFormConfigurations: LabsiteOnlineFormConfigurations[]) {
    this.labsiteOnlineFormConfigurations = LabsiteOnlineFormConfigurations;
  }

  /** his property is being used to set lab site has any Online Form Configurations configured */
  public removeLabsiteOnlineFormConfigurations() {
    this.labsiteOnlineFormConfigurations = [];
  }

  /** This property is being used to check lab site has any Online Form Configurations configured */
  public hasLabsiteOnlineFormConfigurations(): boolean {
    return this.labsiteOnlineFormConfigurations?.length > 0;
  }

  /** This property is being used to get Height Weight Unit */
  public getHeightWeightUnitEnum(): number {
    return this.heightWeightUnitEnum;
  }

  /** his property is being used to set lab site Height Weight Unit */
  public setHeightWeightUnitEnum(heightWeightUnitEnum: number) {
    this.heightWeightUnitEnum = heightWeightUnitEnum;
  }

  /** This property is being used to get Disclaimers Introduction Text */
  public getDisclaimersIntroductionText(): string {
    return this.disclaimersIntroductionText;
  }

  /** This property is being used to set Disclaimers Introduction Text */
  public setDisclaimersIntroductionText(disclaimersIntroductionText: string) {
    this.disclaimersIntroductionText = disclaimersIntroductionText;
  }

  /** This property is being used to get Registration Visit Description Text */
  public getRegistrationVisitDescriptionText(): string {
    return this.registrationVisitDescriptionText;
  }

  /** This property is being used to set Registration Visit Description Text */
  public setRegistrationVisitDescriptionText(registrationVisitDescriptionText: string) {
    this.registrationVisitDescriptionText = registrationVisitDescriptionText;
  }

  /** This property is being used to get Legal Adult Age */
  public getLegalAdultAge(): number|undefined{
    return this.legalAdultAge;
  }

  /** This property is being used to set Legal Adult Age */
  public setLegalAdultAge(legalAdultAge: number|undefined) {
    this.legalAdultAge = legalAdultAge;
  }
}

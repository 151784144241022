export const AppConstants = {
    Blob: {
        ActiveStudies: 'activestudies',
        LabSite: 'labsite',
        Metadata: 'metadata',
        LabsitesURLConfigFolder: 'LabsitesURLConfig',
        LabsitesURLConfigFileName: 'labsitesURLConfig',
        Home: 'Home',
        ActiveStudiesSummary:'activestudiesSummary',
        ActiveStudy:'studyDetail',
        LabsiteByPublicSynchronizer: 'labsiteByPublicSynchronizer',
        LabSiteTextFolder: 'LabSiteTextForPublicWebsite',
        LabSiteTextFileName: 'LabSiteTextForPublicWebsite.json',
        NewsPage: 'NewsPage'
    },
    emptyGUID: '00000000-0000-0000-0000-000000000000',
    Gender: {
        Male: 'Male',
        Female: 'Female',
        Mixed: 'Mixed'
    },
    StudyParticipationStatus: {
        All: 'All',
        Applied: {
            Applied: 'Applied',
            ApplicationHold: 'Application Hold',
            WaitingForFeedback: 'Waiting for Feedback'
        },
        OnGoing: {
            Ongoing: 'Ongoing',
            PreStudyVisit: 'Pre-Study Visit',
            ApplicationAccepted: 'Application Accepted',
            Included: 'Included'
        },
        Passed: {
            Passed: 'Passed',
            Included: 'Included',
            Rejected: 'Application Rejected',
            Withdrawn: 'Withdrawn'
        }
    },
    Paid: 'Paid',
    DATE_FORMATS: {
        parse: {
            dateInput: 'DD/MM/YYYY',
            dbSaveFormat: 'YYYY-MM-DD'
        },
        display: {
            dateDisplayInput: 'DD-MMM-YYYY',
            dateInput: 'DD - MMM - YYYY',
            monthYearLabel: 'MMM YYYY',
            dateA11yLabel: 'MM/DD/YYYY',
            monthYearA11yLabel: 'MMMM YYYY'
        }
    },
    VISIT_DATE_FORMATS: {
        parse: {
            dateInput: 'DD/MM/YYYY',
            dbSaveFormat: 'YYYY-MM-DD'
        },
        display: {
            dateDisplayInput: 'DD-MMM-YYYY',
            dateInput: 'dddd, DD - MMM - YYYY',
            monthYearLabel: 'MMM YYYY',
            dateA11yLabel: 'MM/DD/YYYY',
            monthYearA11yLabel: 'MMMM YYYY'
        }
    },
    TIME_FORMATS: {
        parse: {
            timeInput: 'hh:mm A'
        },
    },
    VisitStatus: {
        Approved: 'Approved',
        Rejected: 'Rejected',
        Pending: 'Pending'
    },
    VolunteerStatus: {
        PendingRegistrationVisit: "Pending Registration Visit",
        Active: "Active",
        Deleted: "Deleted"
    },
    DataState: {
        Created: 'Created',
        Modified: 'Modified',
        UnChanged: 'UnChanged',
        Deleted: 'Deleted'
    },
    MinimumDate: '01-01-0001',
    TimeFormatByLabsite: {
        TwentyFourHourEnum: 0,
        AmOrPmEnum: 1,
        TwentyFourHourFormat: 'HH:mm',
        AmOrPmFormat: 'hh:mm a'
    },
    HomePageMediaTypes: {
        Picture: '0',
        Video: '1'
    },
    Pages: {
        Home: 'HomePage',
        AboutUs: 'AboutUs',
        BeAVolunteer: 'BeAVolunteer',
        ActiveStudies: 'ActiveStudies',
        StudyDetails: 'StudyDetails',
        ContactUs: 'ContactUs',
        PrivacyPolicy: 'PrivacyPolicy',
        TermsOfUse: 'TermsOfUse',
        VolunteerRules: 'VolunteerRules',
        MyActivity: 'MyActivity',
        MyAccount: 'MyAccount',
        MyAppointments: 'MyAppointments',
        MyProductUsage: 'MyProductUsage'
    },
    Events: {
        ApplyStudy: 'ApplyStudy',
        ExternalUser: 'ExternalUserCreated',
        VolunteerRegistration: 'VolunteerRegistrationCompleted',
        AccountDeletion: 'AccountDeletion'
    },
    AppointmentStates: {
        All: 'All',
        Scheduled: 'Scheduled',
        Passed: 'Passed'
    },
    VisitTypes: {
        Registration: "Registration",
        PreStudy: "Pre-Study",
        Study: "Study"
    },
    LegalStatus: {
        Minor: "Minor",
        Adult: "Adult"
    },
    HeightWeightUnit: {
        Enums: {
            MKg: 0,
            InchPounds: 1
        }
    }
};
